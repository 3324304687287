import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import debounce from "@tools/debounce"
import favicon from "@images/profile-pic.jpeg"
import "./layout.scss"

class Layout extends React.Component {
  state = { visible: true, mobile: false }

  handleHideClick = () => this.setState({ visible: false })
  handleShowClick = () => this.setState({ visible: true })
  handleSidebarHide = () => this.setState({ visible: false })
  toggleSidebar = () => this.setState({ visible: !this.state.visible })

  componentDidMount() {
    window.addEventListener("resize", debounce(this.resize.bind(this), 250))
    this.resize()
  }

  resize() {
    let currentHideNav = window.innerWidth <= 1025
    // Are we mobile?
    this.setState({ mobile: currentHideNav })
    // Hide or show sidebar on resize
    if (!currentHideNav !== this.state.visible) {
      this.setState({ visible: !currentHideNav })
    }
  }

  render() {
    const { title, description } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                sidebar {
                  pages {
                    slug
                    title
                    pages {
                      slug
                      title
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet

              title={
                title
                  ? `${title} - ${data.site.siteMetadata.title}`
                  : data.site.siteMetadata.title
              }
              meta={[
                {
                  name: "description",
                  content: description
                    ? description
                    : "Documentation for Semantic UI Component Library",
                },
                {
                  name: "keywords",
                  content: "design system, style guide, documentation",
                },
              ]}
            >
              <html lang="en" />
              <link rel="icon" href={favicon} />
            </Helmet>
          </>
        )}
      />
    )
  }
}

export default Layout
